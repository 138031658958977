export const FETCH_COMMON = 'FETCH_COMMON'

export const RECEIVE_PROFILE_USER = 'RECEIVE_PROFILE_USER'

export const RECEIVE_SEARCH = 'RECEIVE_SEARCH'

export const RECEIVE_COIN_FAIR = 'RECEIVE_COIN_FAIR'

export const RECEIVE_COIN_FRENTE = 'RECEIVE_COIN_FRENTE'

export const RECEIVE_COIN_SAGITUR = 'RECEIVE_COIN_SAGITUR'

export const RECEIVE_COIN_VISION = 'RECEIVE_COIN_VISION'